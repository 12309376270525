import logo01 from '../assets/images/logo01.png';
import imgBronze from '../assets/images/ico_copper@2x.png';
import imgSilver from '../assets/images/ico_silver@2x.png';
import imgGold from '../assets/images/ico_gold@2x.png';

export const ASSETS_IMG = {
  logo01,
  imgBronze,
  imgGold,
  imgSilver,
};
