import { createContext, ReactNode, useEffect, useState } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { useAppSelector } from '../../hooks';
import { AuthState } from '@aws-amplify/ui-components';
import UnderMaintenanceModal from '../Modal/UnderMaintenanceModal/UnderMaintenanceModal';

type RouteProvideProps = {
  children: ReactNode;
};

const RouteWrapper = ({ children }: RouteProvideProps) => {
  const userState = useAppSelector((state) => state.user);
  useEffect(() => {
    if (userState.authState === AuthState.SignedIn && userState.user) {
      requestServerApi<any>(() => serverApi.organizationsControllerGetOperationalStatus()).then((result) => {
        if (result?.data?.data.length > 0 && result.data.data[0].status === true) {
          setSelectedModal('UnderMaintenanceModal');
          setSelectedItem(result.data.data[0].data);
        }
      });
    }
  }, [userState]);
  const [selectedModal, setSelectedModal] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>('');

  return (
    <>
      {children}
      {'UnderMaintenanceModal' === selectedModal && (
        <UnderMaintenanceModal maintenances={selectedItem} />
      )}
    </>
  );
};
export default RouteWrapper;
