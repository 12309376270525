// お知らせを調整する
export const getFormattedNotice = (noticeList: any) => {
  // 現在日時を取得
  let currentDatetime = new Date();
  // 3日後にオフセット
  currentDatetime.setDate(currentDatetime.getDate() - 3);
  for (let i = 0; i < noticeList.length; i++) {
    let startDatetime = new Date(noticeList[i].start_datetime);
    if (currentDatetime < startDatetime) {
      noticeList[i]['new'] = true;
    } else {
      noticeList[i]['new'] = false;
    }
  }

  return noticeList;
};

// 日付を調整する
export const getFormattedDate = (datetimeStr: string) => {
  if (!datetimeStr) return '';
  let datetime = new Date(datetimeStr);
  let adjustedDate = getAdjustedDate(datetime);

  return adjustedDate;
};

export const getFormattedTime = (datetimeStr: string) => {
  if (!datetimeStr) return '';
  let datetime = new Date(datetimeStr);
  let adjustedtime = getAdjustedTime(datetime);

  return adjustedtime;
};

export const getFormattedDatetime = (datetimeStr: string) => {
  if (!datetimeStr) return '';
  let datetime = new Date(datetimeStr);
  let adjustedDate = getAdjustedDate(datetime);

  let adjustedtime = getAdjustedTime(datetime);

  return adjustedDate + ' ' + adjustedtime;
};

export const getFormattedNumberWithCommas = (val: number) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getAdjustedDate = (datetime: Date) => {
  let adjustedDate =
    datetime.getFullYear() +
    '-' +
    ('00' + (datetime.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + datetime.getDate()).slice(-2);

  return adjustedDate;
};

const getAdjustedTime = (datetime: Date) => {
  let adjustedTime =
    ('00' + datetime.getHours()).slice(-2) + ':' + ('00' + datetime.getMinutes()).slice(-2);
  return adjustedTime;
};

export const getFormattedYearMonth = (dateStr: string) => {
  var adjustedDate: string =
    dateStr !== undefined ? `${dateStr.substring(0, 4)}年${dateStr.substring(4, 6)}月` : '';
  return adjustedDate;
};
export const getHoursAndMinutes = (totalMinutes: number) => {
  if (totalMinutes < 60) {
    return `00 : ${totalMinutes}`;
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const time = `${hours} :` + (minutes > 10 ? `${minutes}` : ` 0${minutes}`);
  return time;
};

export const getFormattedMonthDatetime = (datetimeString: string) => {
  try {
    const date = new Date(datetimeString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid datetime string');
    }
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('ja-JP', options).format(date);
  } catch (error) {
    console.error('Error formatting datetime:', error);
    return 'Invalid datetime';
  }
};