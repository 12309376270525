export const ERROR_CODE = {
  HTTP_401: '401_Unauthorized',
  HTTP_404: '404_NOT_FOUND',
  /** ErrorBoundaryでキャッチされるエラー */
  E_9000: 'E_9000_COMPONENT_ERROR',
  E_9001: 'E_9001_ERROR_PAGE_COMPONENT',

  /** ユーザーイベントログ */
  E_9100: 'E_9100_USER_EVENT_LOG_ERROR',
  /** エラーログの内部 */
  E_9900: 'E_9900_SEND_ERROR_LOG_ERROR',
  /** 不明 */
  E_9999: 'E_9999_UNEXPECTED_ERROR',
} as const;

export type ERROR_CODE = typeof ERROR_CODE;
