import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  Heading,
} from '@chakra-ui/react';
import { getFormattedMonthDatetime } from '../../Format';

interface MaintenanceEntry {
  maintenance_title: string;
  maintenance_detail: string;
  start_datetime: string;
  end_datetime: string;
  id: string;
}

type Props = {
  maintenances: MaintenanceEntry[];
};

export default function UnderMaintenanceModal({ maintenances }: Props) {
  const closeModal = () => {};

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="50rem">
          <>
            <ModalBody>
              <VStack align="center" justify="center">
                <Heading as="h2" size="xl" mb="4" marginTop={15}>
                  メンテナンス実施中
                </Heading>
              </VStack>
              <Text fontSize="lg" mb="4">
                いつもはなすプラスをご利用いただき誠にありがとうございます。
              </Text>
              <Text fontSize="lg">
                システムメンテナンスのため、以下のとおりサービスがご利用いた だけません。
              </Text>
              <Text fontSize="lg" mb="4" marginTop={'30px'}>
                ご不便をおかけいたしますことをおわび申し上げます。
              </Text>
              <Text fontSize="xl" mb="4" marginTop={'30px'}>
                停止期間
              </Text>
              <Text fontSize="lg" mb="4">
                {getFormattedMonthDatetime(maintenances[0]?.start_datetime)} ~{' '}
                {getFormattedMonthDatetime(maintenances[0]?.end_datetime)}
              </Text>
              <Text fontSize="xl" mb="4" mt={'30px'}>
                 詳細
              </Text>
              <Text fontSize="lg" mb="4">
                {maintenances[0]?.maintenance_title}
              </Text>
              <Text fontSize="lg" mb="8">
                {maintenances[0]?.maintenance_detail}
              </Text>
            </ModalBody>
          </>
        </ModalContent>
      </Modal>
    </>
  );
}
